import {
    Add,
    BadgeOutlined,
    DashboardOutlined,
    PeopleOutline
} from "@mui/icons-material";
import NewClientPage from "./pages/admin/NewClientPage";
import ClientsPage from "./pages/admin/clients";
import MainPage from "./pages/main";
import UsersDashboard from "./pages/users/dashboard";

const config = {
    application: {
        name: "Bitswave",
        logo: "/static/images/btc.jpg",
        auth: {
            enableRegister: true,
        },
        commonConfig: {
            showOfflineDialog: false
        },
        theme: {
            mode: "light",
            primary: {
                main: "#03103b"
            },
            secondary: {
                main: "#3498db"
            },
        }
    },
    pages: [
        {
            path: '/',
            spec: {
                type: "Simple",
                object: <MainPage />
            }
        }
    ],
    dashboards: [
        {
            path: "/admin",
            name: {
                type: "Literal",
                text: "Admin",
            },
            roles: ["admin"],
            items: [
                {
                    path: "clients",
                    name: {
                        type: "Localized",
                        text: "business.clients",
                    },
                    icon: <PeopleOutline />,
                    hide: false,
                    spec: {
                        type: "Simple",
                        object: <ClientsPage />,
                        fabs: [
                            {
                                color: "primary",
                                icon: <Add />,
                                onClick: () => {
                                    window.location.href = '/admin/client';

                                }
                            }
                        ]
                    }
                },{
                    path: "client",
                    name: {
                        type: "Localized",
                        text: "business.newClient",
                    },
                    icon: <BadgeOutlined />,
                    hide: false,
                    spec: {
                        type: "Simple",
                        object: <NewClientPage />,
                    }
                }
            ]
        },
        {
            path: "/users",
            name: {
                type: "Literal",
                text: "Users",
            },
            roles: ["normal"],
            items: [
                {
                    path: "dashboard",
                    name: {
                        type: "Localized",
                        text: "business.dashboard",
                    },
                    icon: <DashboardOutlined />,
                    hide: false,
                    spec: {
                        type: "Simple",
                        object: <UsersDashboard />
                    }
                }
            ]
        }
    ],
    languages: {
        default: 'en',
        list: [
            { code: 'en', name: 'English' },
        ]
    }
}

export default config;