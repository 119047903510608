import { Children, cloneElement, isValidElement } from "react";
import { Navigate } from "react-router-dom";
import Loader from "../Components/Loader";
import { useMicrospecDynamicUIContext } from "../GlobalProvider";
import getPagesElement from "../Pages/ElementsMapper";

const AuthRoute = ({ path, element, roles }) => {
  const { cloudProvider } = useMicrospecDynamicUIContext();
  const { user, userProfile, loading, signOut } = cloudProvider;
  var internalElement;
  if (loading) {
    internalElement = <Loader />;
  } else if (!user && !loading) {
    const loginPath = path ? "/login?redirectTo=" + path : "/login";
    internalElement = <Navigate to={loginPath} replace />;
  } else if (!userProfile || !userProfile.completed) {
    internalElement = <Loader />;
  } else {
    if (roles.length === 0) {
      internalElement = Children.map(element, (child, index) => {
        return isValidElement(child)
          ? cloneElement(child, { user, signOut, key: `element-${index}` })
          : child;
      });
    } else {
      const intersect = roles.filter((role) => {
        return userProfile.attributes.roles.includes(role);
      });
      if (intersect.length !== 0) {
        internalElement = Children.map(element, (child) => {
          return isValidElement(child)
            ? cloneElement(child, { user, signOut })
            : child;
        });
      } else {
        internalElement = getPagesElement({ type: "NotFound" });
      }
    }
  }
  return internalElement;
};

export default AuthRoute;
