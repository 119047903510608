import { SaveOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  lighten,
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import PropTypes from "prop-types";
import * as React from "react";
import Loader from "../../../MicrospecDynamicUI/Components/Loader";
import { useMicrospecDynamicUIContext } from "../../../MicrospecDynamicUI/GlobalProvider";
import "./clients.css";

const getData = async (setData, firestore) => {
  const db = firestore.instance;
  const usersCollection = firestore.user.collectionName;
  const usersCollectionRef = collection(db, usersCollection);
  const q = query(
    usersCollectionRef,
    where("roles", "array-contains", "normal")
  );
  const usersData = await getDocs(q)
    .then((querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data;
    })
    .catch(() => {
      return [];
    });
  const usersTokensPromises = usersData.map(async (user) => {
    const userDocRef = doc(db, `${usersCollection}/${user.id}/tokens/usdt`);
    const data = await getDoc(userDocRef)
      .then((docData) => {
        if (docData.exists()) {
          return {
            balance: 0,
            name: user.first_name + " " + user.last_name,
            id: user.id,
            token: docData.id,
            ...docData.data(),
          };
        } else {
          return {
            name: user.first_name + " " + user.last_name,
            id: user.id,
            balance: 0,
          };
        }
      })
      .catch(() => {
        return {
          name: user.first_name + " " + user.last_name,
          id: user.id,
          balance: 0,
        };
      });
    return data;
  });
  const usersTokens = await Promise.all(usersTokensPromises);
  // console.log(usersTokens);
  setData(usersTokens);
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "balance",
    numeric: true,
    disablePadding: true,
    label: "Balance",
  },
];

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            component="th"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                fontWeight: 600,
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Clients
        </Typography>
      )}

      {numSelected > 0 && false ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ClientsPage() {
  const { cloudProvider } = useMicrospecDynamicUIContext();
  const { firestore, userProfile } = cloudProvider;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [initialBalanceValue, setInitialBalanceValue] = React.useState();
  const [isVerifyBalanceChangeDialogOpen, setVerifyBalanceChangeDialogOpen] =
    React.useState();
  const [rows, setRows] = React.useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (rows ? rows.length : 0))
      : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows || [], getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const handleVerifyBalanceChange = () => {
    if (initialBalanceValue) {
      const { id, initialBalance } = initialBalanceValue;
      setVerifyBalanceChangeDialogOpen(id && initialBalance >= 0);
    }
  };

  const handleCloseVerifyChangeBalanceClose = () => {
    // revert to the previous amount
    const { index, initialBalance } = initialBalanceValue;
    const client = visibleRows[index];
    client.balance = initialBalance;
    // clean the balance state and close dialog
    setInitialBalanceValue();
    setVerifyBalanceChangeDialogOpen();
  };

  const getClientIndex = (id) => rows.findIndex((el) => el.id === id);

  const handleSaveNewBalanceValue = async () => {
    // console.log(initialBalanceValue);
    const { id } = initialBalanceValue;
    const temp = [...rows];
    const index = getClientIndex(id);
    if (index > -1) {
      const userRef = doc(firestore.instance, `users/${id}/tokens/usdt`);
      setDoc(
        userRef,
        {
          balance: temp[index].balance,
        },
        { merge: true }
      );
      setInitialBalanceValue();
      setVerifyBalanceChangeDialogOpen();
    }
  };

  const getCurrentBalance = (clientId) => {
    const index = getClientIndex(clientId);
    if (index > -1) {
      return rows[index].balance || 0;
    }
  };

  React.useMemo(() => {
    if (!rows && firestore && userProfile) {
      getData(setRows, firestore, userProfile);
    }
  }, [rows, firestore, userProfile]);
  return (
    <>
      {!rows && <Loader />}
      {rows && rows.length === 0 && <div>No clients found</div>}
      {rows && rows.length > 0 && (
        <Box sx={{ p: 2, width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                sx={{ overflow: "auto" }}
                aria-labelledby="tableTitle"
                // size="medium"
              >
                <EnhancedTableHead
                  // numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  bgcolor="black"
                />
                <TableBody>
                  {visibleRows?.map((row, index) => {
                    // const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const iconElId = `saveBtn-${row.id}`;
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        // role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: "pointer", py: 1 }}
                        bgcolor={index % 2 === 1 ? "#f2f2f2" : "transparent"}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            /> */}
                        </TableCell>
                        <TableCell
                          component="td"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: 120,
                            maxWidth: 120,
                          }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          component="td"
                          id={labelId + "Email"}
                          scope="row"
                          padding="none"
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          component="td"
                          id={labelId + "Balance"}
                          scope="row"
                          padding="none"
                        >
                          <TextField
                            onChange={(e) => {
                              const temp = [...rows];
                              const index = getClientIndex(row.id);
                              if (index > -1) {
                                temp[index].balance = Number(e.target.value);
                                setRows(temp);
                              }
                            }}
                            onFocus={(e) => {
                              // console.log(Number(e.target.value));
                              setInitialBalanceValue({
                                id: row.id,
                                initialBalance: Number(e.target.value),
                                index,
                              });
                            }}
                            // onPointerEnter={() => {
                            //   console.log(1)
                            // }}
                            type="number"
                            className="client-textfield"
                            id={`client-balance-${row.id}`}
                            sx={{
                              [`&:hover #${iconElId}`]: {
                                display: "block",
                              },
                            }}
                            variant="standard"
                            inputMode="numeric"
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                position: "relative",
                              },
                              className: "client-input",
                              endAdornment: (
                                <IconButton
                                  onClick={() => {
                                    handleVerifyBalanceChange(index);
                                  }}
                                  size="small"
                                  id={iconElId}
                                  color="default"
                                  sx={{
                                    display: "none",
                                    position: "absolute",
                                    right: 0,
                                    ":hover": {
                                      color: (theme) =>
                                        theme.palette.success.main,
                                    },
                                    zIndex: 9,
                                  }}
                                >
                                  <SaveOutlined />
                                </IconButton>
                              ),
                            }}
                            value={row.balance}
                          />
                        </TableCell>
                        {/* <TableCell
                      component="td"
                      id={labelId + "Balance-action"}
                      scope="row"
                      padding="none"
                    >
                      <Button variant="outlined">Save</Button>
                    </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
      {isVerifyBalanceChangeDialogOpen && (
        <Dialog
          open={Boolean(isVerifyBalanceChangeDialogOpen)}
          onClose={handleCloseVerifyChangeBalanceClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Client Balance</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Lets just verify one last thing:</Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
                item
                container
                xs={12}
                spacing={1}
              >
                <Grid item xs={6} md={4}>
                  <Card
                    elevation={2}
                    sx={{
                      backgroundColor: (theme) =>
                        lighten(theme.palette.error.light, 0.85),
                    }}
                  >
                    <CardHeader title="Initial Balance" />
                    <CardContent>
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.error.main,
                          fontSize: "2rem",
                        }}
                      >
                        {initialBalanceValue.initialBalance}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Card
                    elevation={2}
                    sx={{
                      backgroundColor: (theme) =>
                        lighten(theme.palette.secondary.light, 0.85),
                    }}
                  >
                    <CardHeader title="Current Balance" />
                    <CardContent>
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.secondary.main,
                          fontSize: "2rem",
                        }}
                      >
                        {getCurrentBalance(initialBalanceValue.id)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleSaveNewBalanceValue()}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={handleCloseVerifyChangeBalanceClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
