import PropTypes from "prop-types";

import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// import Iconify from 'src/components/iconify';
import {
    CommentOutlined,
    MoreVertOutlined,
    ShareOutlined,
    VisibilityOutlined,
} from "@mui/icons-material";
import { Icon } from "@mui/material";
import { useState } from "react";
import { isRTL } from "../../Helpers/Utils";
import SvgColor from "../SvgColor";
import svgShape from "./shape-avatar.svg";

const BannerProfileDateTitledStatsCard = ({ post, actionsList }) => {
  const { cover, title, views, comment, share, author, createdAt } = post;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const notchSide = isRTL() ? "right" : "left";
  const renderAvatar = (
    <Avatar
      alt={author.name}
      src={author.avatarUrl}
      sx={{
        zIndex: 9,
        width: 32,
        height: 32,
        position: "absolute",
        [notchSide]: (theme) => theme.spacing(3),
        bottom: (theme) => theme.spacing(-2),
      }}
    />
  );

  const renderTitle = (
    <Link
      color="inherit"
      variant="h4"
      underline="hover"
      sx={{
        height: 44,
        overflow: "hidden",
        WebkitLineClamp: 2,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
      }}
    >
      {title}
    </Link>
  );

  const renderInfo = (
    <Stack
      direction="row"
      flexWrap="wrap"
      gap={1}
      justifyContent="flex-end"
      sx={{
        mt: 3,
        color: "text.disabled",
      }}
    >
      {[
        { number: comment, icon: <CommentOutlined fontSize="small" /> },
        { number: views, icon: <VisibilityOutlined fontSize="small" /> },
        { number: share, icon: <ShareOutlined fontSize="small" /> },
      ].map((info, _index) => (
         info.number && <Stack key={_index} direction="row">
          <Icon>{info.icon}</Icon>
          <Typography variant="caption">{info.number}</Typography>
        </Stack>
      ))}
    </Stack>
  );

  const renderCover = (
    <Box
      component="img"
      alt={title}
      src={cover}
      sx={{
        top: 0,
        width: 1,
        height: 1,
        objectFit: "cover",
        position: "absolute",
      }}
    />
  );

  const renderDate = (
    <Typography
      variant="caption"
      component="div"
      sx={{
        mb: 2,
        color: "text.disabled",
      }}
    >
      {createdAt}
    </Typography>
  );

  const renderShape = (
    <SvgColor
      color="paper"
      src={svgShape}
      sx={{
        width: 80,
        height: 36,
        zIndex: 9,
        bottom: -15,
        position: "absolute",
        color: "background.paper",
      }}
    />
  );

  const renderActionButton = (
    <>
      {actionsList && actionsList.length > 0 && (
        <>
          <IconButton
            sx={{
              position: "absolute",
              top: 5,
              left: 5,
              zIndex: 2,
              backgroundColor: (theme) => theme.palette.action.disabled,
              color: (theme) => theme.palette.common.white,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.action.focus,
                // color: (theme) => theme.palette.action.disabled,
              },
            }}
            onClick={handleClick}
          >
            <MoreVertOutlined />
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {actionsList.map((listItem) => (
              <MenuItem
                key={listItem.name}
                onClick={(e) => {
                  listItem.handler({ ...listItem, cardInfo: post }, e);
                  handleClose();
                }}
              >
                {listItem.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <Card
        sx={{
          ":hover": {
            boxShadow: "0 2px 14px 0 rgb(32 40 45 / 50%)",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            pt: "calc(100% * 3 / 4)",
          }}
        >
          {renderActionButton}

          {renderShape}

          {renderAvatar}

          {renderCover}
        </Box>

        <Box
          sx={{
            p: (theme) => theme.spacing(4, 3, 3, 3),
          }}
        >
          {renderDate}

          {renderTitle}

          {renderInfo}
        </Box>
      </Card>
    </Grid>
  );
};

BannerProfileDateTitledStatsCard.propTypes = {
  cover: PropTypes.string,
  title: PropTypes.string,
  views: PropTypes.number,
  comment: PropTypes.number,
  share: PropTypes.number,
  author: PropTypes.string,
  createdAt: PropTypes.string,
};

export default BannerProfileDateTitledStatsCard;
