import { Grid, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useMemo, useState } from "react";
import BannerProfileDateTitledStatsCard from "../../../MicrospecDynamicUI/Components/BannerProfileDateTitledStatsCard";
import Loader from "../../../MicrospecDynamicUI/Components/Loader";
import { useMicrospecDynamicUIContext } from "../../../MicrospecDynamicUI/GlobalProvider";
import PersonalGreetingCard from "../../components/PersonalGreetingCard/PersonalGreetingCard";
// import { getDoc } from "firebase/firestore";
// import { log } from "firebase-functions/logger";

const getData = async (setData, setRetrieved, firestore, userProfile) => {
  const db = firestore.instance
  const collection = firestore.user.collectionName
  console.log(userProfile.attributes.uid);
  const uid = userProfile.attributes.uid
  const fullCollection = doc(db, `${collection}/${uid}/tokens/usdt`);
  const data = await getDoc(fullCollection).then((docData) => {
    if (docData.exists()) {
      return { ...docData.data(), id: docData.id }
    } else {
      return {}
    }
  }).catch(() => {
    return {}
  });
  setData(data);
  setRetrieved(true)
}

const UsersDashboard = () => {
  const { cloudProvider } = useMicrospecDynamicUIContext();
  const { firestore, userProfile } = cloudProvider
  const [retrieved, setRetrieved] = useState(false)
  const [data, setData] = useState({});
  useMemo(() => {
    if (!retrieved && firestore && userProfile) {
      getData(setData, setRetrieved, firestore, userProfile)
    }
  }, [retrieved, firestore, userProfile])

  return (
    <>
      {!retrieved && <Loader />}
      {retrieved &&
        <Grid container spacing={2} p={2}>
          <BannerProfileDateTitledStatsCard
            post={{
              title: <Typography variant="subtitle1">Account Balance: {data.balance ? data.balance.toFixed(2) : 0.0} USDT</Typography>,
              createdAt: data.lastUpdated && `Last update: ${new Date(data.lastUpdated.seconds * 1000).toLocaleDateString("en-GB")}`,
              cover: "/static/images/usdt.png",
              author: {
                name: "Tether USDT",
                avatarUrl: "/static/images/balance.jpg",
              },
            }}
          />
          <Grid item xs={12} md={6}>
            <PersonalGreetingCard />
          </Grid>
        </Grid>
      }
    </>
  );
};

export default UsersDashboard;
