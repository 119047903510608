import { Timestamp } from "@firebase/firestore";
import { Card, Stack, Typography } from "@mui/material";
import { darken } from "@mui/material/styles";
import holdingCrypto from "../../images/holding-crypto.png";

const PersonalGreetingCard = () => {

  const handleTimeBasedGreeting = () => {
    const timestamp = Timestamp.now().seconds;
    const time = new Date(timestamp * 1000).getHours();
    console.log(time);
    let greeting;
    if (time > 6 && time < 11) {
      greeting = "Good morning";
    } else if (time >= 11 && time < 15) {
      greeting = "Good Afternoon";
    } else if (time >= 15 && time < 19) {
      greeting = "Hello";
    } else if (time >= 19 && time < 21) {
      greeting = "Good Evening";
    } else {
      greeting = "Good Night";
    }
    return greeting;
  };
  return (
    <>
      <Card
        sx={{
          px: 5,
          boxShadow: 0,
          bgcolor: (theme) => darken(theme.palette.primary.dark, 0.25),
          color: (theme) => theme.palette.primary.contrastText,
          height: "100%",
          alignItems: "space-between",
          justifyContent: "space-around",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          sx={{ alignItems: "center", justifyContent: "center" }}
          direction={{ xs: "column", md: "row" }}
        >
          <img
            style={{
              width: "70%",
              minWidth: "160px",
            }}
            alt="holding crypto"
            src={holdingCrypto}
          />
          <Stack gap={1}>
            <Typography fontSize="2rem">
              {handleTimeBasedGreeting()}
            </Typography>
            <Typography textAlign="justify">
              Stay updated and keep up with your portfolio.
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </>
  );
};

export default PersonalGreetingCard;
