import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import mainBg from "../images/banner-bg.png";

function MainHeader() {
  return (
    <Grid
      container
      bgcolor={"#03103b"}
      sx={{
        height: "100vh",
        width: "100vw",
        overflowX: {
          xs: "hidden",
          md: "initial",
        },
      }}
      position={"relative"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        container
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography
            variant={"h3"}
            sx={{
              backgroundColor: "#1a1d5f",
              color: "#fff",
              padding: "15px 20px",
              borderRadius: "40px",
              width: "70%",
              textAlign: "center",
            }}
            mx={"auto"}
          >
            Join us today and enjoy the rewards.
          </Typography>
          <Typography
            variant="h1"
            sx={{
              color: "#fff",
              fontSize: "2.5rem",
              fontWeight: "bold",
              padding: "20px 0",
              width: "70%",
              // textAlign: "center",
            }}
            mx={"auto"}
          >
            Welcome to the next level of crypto trading technology
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#496b96",
              fontSize: "1rem",
              padding: "20px 0",
              width: "70%",
              // textAlign: "center",
            }}
            mx={"auto"}
          >
            We are experts in the domain of cryptocurrency trading. We provide
            you with the best trading experience. With our platform, you can
            trade with ease and enjoy the rewards. Our experts are always
            available to help you and get you the best deals.
          </Typography>
          <Grid
            item
            display={"flex"}
            gap={2}
            sx={{
              width: "70%",
            }}
            mx={"auto"}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                window.location.href = "/users";
              }}
            >
              LOGIN
            </Button>
            <Button variant="outlined" color="secondary">
              CONTACT US
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sm={4}
        md={6}
        sx={{
          display: { xs: "none", sm: "flex" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: "80%", md: "90%" },
            position: {
              xs: "absolute",
              md: "initial",
            },
            right: {
              xs: "-45%",
            },
          }}
          alt="Main Background"
          src={mainBg}
        />
      </Grid>
    </Grid>
  );
}

export default MainHeader;
