import { Add } from "@mui/icons-material";
import { Box, Fab, Typography } from "@mui/material";
import { getOrDefault, isRTL } from "../../../Helpers/Utils";
import { useMicrospecThemeLanguageContext } from "../../../Themes";

const SimpleElement = (spec) => {
  const { localizationProvider } = useMicrospecThemeLanguageContext();
  const fabs = getOrDefault(spec.fabs, []);
  const fabSide = isRTL() ? "left" : "right";

  return (
    <Box>
      {fabs.map((fab, index) => {
        return (
          <Fab
            sx={{
              position: "fixed",
              bottom: 35 + index * 70,
              [fabSide]: 35,
            }}
            aria-label={`fab-${index}`}
            color={fab.color}
            onClick={fab.onClick || (() => {})}
          >
            {getOrDefault(fab.icon, <Add />)}
          </Fab>
        );
      })}
      {spec.object ? (
        spec.object
      ) : (
        <Typography variant="h4">
          {localizationProvider.translateByType({
            type: "Internal",
            text: "simpleElementText",
          })}
        </Typography>
      )}
    </Box>
  );
};

export default SimpleElement;
