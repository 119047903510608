import { AddBoxOutlined } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { useMicrospecDynamicUIContext } from "../../../MicrospecDynamicUI/GlobalProvider";
import { useMicrospecThemeLanguageContext } from "../../../MicrospecDynamicUI/Themes";

export default function NewClientPage() {
  const { localizationProvider } = useMicrospecThemeLanguageContext();
  const { cloudProvider } = useMicrospecDynamicUIContext();
  // const [_, setPasswordMessage] = useState(false)
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccess(false);
    setError(false);
    // setPasswordMessage(false);
    const data = new FormData(event.currentTarget);
    const firstName = data.get("firstName");
    const lastName = data.get("lastName");
    const email = data.get("email");
    const createNewUser = httpsCallable(
      cloudProvider.serverlessFunctions.instance,
      "createNewUser"
    );
    await createNewUser({
      email,
      first_name: firstName,
      last_name: lastName,
    }).then((res) => {
      // console.log("From Then", res);
      const {
        data: { success, error },
      } = res;
      if (!success && error) {
        const {
          errorInfo: { code },
        } = error;
        switch (code) {
          case "auth/email-already-exists":
            setSuccess(false);
            setError(true);
            break;
          default:
            setSuccess(false);
            setError(true);
            break;
        }
      } else {
        setSuccess(true);
        setError(false);
      }
    })
      .catch(() => {
        setSuccess(false);
        setError(true);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <AddBoxOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Add New Client
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            name="firstName"
            label={localizationProvider.translateByType({
              type: "Internal",
              text: "firstName",
            })}
            autoComplete="firstName"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            name="lastName"
            autoComplete="lastName"
            label={localizationProvider.translateByType({
              type: "Internal",
              text: "lastName",
            })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={localizationProvider.translateByType({
              type: "Internal",
              text: "emailAddress",
            })}
            name="email"
            autoComplete="email"
          />
          {success && (
            <Alert severity="success">
              New client was created successfully.
            </Alert>
          )}
          {error && (
            <Alert severity="error">
              Something went wrong, maybe email is already registered, please try again later.
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {localizationProvider.translateByType({
              type: "Literal",
              text: "Add new client",
            })}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
