import useFirebase from "./Firebase";
import useAWS from "./AWS"

const providerMapper = {
    FIREBASE: useFirebase,
    AWS: useAWS
}
const useCloudProviderProperties = (config) => {
    const provider = process.env.REACT_APP_CLOUD_PROVIDER
    return providerMapper[provider](config);
}

export default useCloudProviderProperties